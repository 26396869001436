import '@/styles/globals.css'
import type { AppProps } from 'next/app';
import { Inter } from 'next/font/google';

export const metadata = {
  title: 'Create Next App',
  description: 'Generated by create next app',
};

const inter = Inter({ subsets: ['latin'] });

export default function App({ Component, pageProps }: AppProps) {
  return <Component {...pageProps} className={inter.className} />
}
